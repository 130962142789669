import React, { useContext, useEffect } from 'react';
import ListItem from '../components/list-item';
import { PATH } from '../helper/config';
import { Helmet } from 'react-helmet';

import CategoryIndex from '../components/journal/category-index';
import Pager from '../components/utils/pager';
import { AppContext, ThemeColor } from '../context/context';
import { OnlyPC, OnlySP } from '../helper/media-helper';
import CategoryIndexSp from '../components/journal/category-index-sp';
import { graphql } from 'gatsby';

const CategoryPage: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.JOURNAL}category/${pageContext.slug}`,
        en: `/en${PATH.JOURNAL}category/${pageContext.slug}`
      },
      pageContext.locale
    );
  }, []);

  useEffect(() => {
    switch (pageContext.slug) {
      case 'another-story':
        context.setTheme(ThemeColor.BLACK);
        break;
      case 'artist-talk':
        context.setTheme(ThemeColor.DEFAULT);
        break;
      case 'life-style':
        context.setTheme(ThemeColor.BLACK);
        break;
      case 'short-column':
        context.setTheme(ThemeColor.WHITE);
        break;
      case 'pf-product':
        context.setTheme(ThemeColor.BROWN);
        break;
      case 'the-review':
        context.setTheme(ThemeColor.DARK_GRAY);
        break;
      case 'another-story-en':
        context.setTheme(ThemeColor.BLACK);
        break;
      case 'artist-talk-en':
        context.setTheme(ThemeColor.DEFAULT);
        break;
      case 'life-style-en':
        context.setTheme(ThemeColor.BLACK);
        break;
      case 'short-column-en':
        context.setTheme(ThemeColor.WHITE);
        break;
      case 'pf-product-en':
        context.setTheme(ThemeColor.BROWN);
        break;
      case 'the-review-en':
        context.setTheme(ThemeColor.DARK_GRAY);
        break;
    }
    return () => {
      context.setTheme(ThemeColor.DEFAULT);
    };
  }, [pageContext.slug]);
  return (
    <div className="journal util__page">
      <Helmet>
        <title>{pageContext.categoryData[pageContext.slug].label} | POST-FAKE</title>
        <meta property="og:title" content={`${pageContext.categoryData[pageContext.slug].label} | POST-FAKE`} />
        <meta name="twitter:site" content={`${pageContext.categoryData[pageContext.slug].label} | POST-FAKE`} />
        <meta name="twitter:title" content={`${pageContext.categoryData[pageContext.slug].label} | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.JOURNAL}category/${pageContext.slug}`} />
      </Helmet>
      <OnlySP>
        <CategoryIndexSp data={pageContext.categoryData} slug={pageContext.slug} locale={pageContext.locale} />
      </OnlySP>
      <div className="journal__list">
        <OnlyPC classStr="category_pc">
          <CategoryIndex data={pageContext.categoryData} slug={pageContext.slug} locale={pageContext.locale} />
        </OnlyPC>
        {data.allWpGqlJournalItem.edges.map((elem: any, i: number) => {
          let largeFlag: boolean = false;
          if (i % 5 === 0) largeFlag = true;
          return <ListItem isLarge={largeFlag} data={elem} key={`journal-list-item-${i}`} locale={pageContext.locale} />;
        })}
      </div>
      <Pager context={pageContext} basePath={PATH.JOURNAL} path={PATH.JOURNAL_PAGE} locale={pageContext.locale} />
    </div>
  );
};
export default CategoryPage;

export const query = graphql`
  query journalCategoryListQuery($skip: Int!, $limit: Int!, $slug: String!, $locale: String!) {
    allWpGqlJournalItem(
      skip: $skip
      limit: $limit
      filter: { gqlJournalCategories: { nodes: { elemMatch: { slug: { eq: $slug } } } }, locale: { locale: { eq: $locale } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          databaseId
          date
          gqlJournalCategories {
            nodes {
              name
            }
          }
          optionJournalFields {
            title
            thumbnail {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
